export function errorHandler<
  T extends { error: string | Record<string, string> }
>(
  state: T,
  payload:
    | string
    | {
        message: string;
        error?: string;
      }
) {
  if (typeof payload === "string") {
    state.error = payload;
  } else if (payload?.message || payload?.error) {
    state.error = payload.message || payload.error || "";
  } else {
    state.error = "Unknown error occurred";
  }
}
