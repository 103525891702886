export const regex = {
  mailFormat:
    /^[a-zA-Z0-9](?!.*[._%+-]{2})[a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  passwordFormat: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
  urlFormat:
    /(?<=\s|^)((https?|ftp):\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?\b/,
  linkedinUrlFormat: /^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/.*$/,
  stringFormat: /^[A-Za-z]+$/,
  numberFilter: /^(>|<|=)\d+$|^\d+-\d+$/,
};
