import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  mapToURL,
  // SavedSearches,
} from "../../constants/userFlowConstants";
import {
  createSavedSearches,
  deleteSavedSearches,
  getAllDataForFilters,
  getSavedSearches,
  removeNullValues,
  // sendDatatoFilterService,
  sendDatatoFilterServiceCall,
  setViewedForSavedSearch,
} from "../../services/filterService";
import { setFilteredData } from "./userFlowSlice";
import { processFilterFormat } from "../../utils/userFlow";
import { RESPONSE_STATUS } from "../../enums/enums";
import { AxiosError } from "axios";
import { errorHandler } from "./helper/errorHandler";

type InitialState = Record<string, any> & { error: string };

let initialState: InitialState = {
  position: [],
  companyName: [],
  companyHQ: [],
  industry: [],
  companyEmployeeCount: [],
  fundingRaised: [],
  jobTitle: [],
  trigger: [],
  selectedFilters: {},
  viewFilter: {
    filters: "",
    currentFilterId: "",
    filterName: "",
    viewFlag: false,
    screenName: "",
  },
  filterFlag: false,
  savedSearches: null,
  currentFilterId: "",
  filterName: "",
  editAndSave: false, // intially false
  notification: {
    type: "",
    message: "",
  },
  filterDrawer: false,
  applyFilter: false,
  status: RESPONSE_STATUS.IDLE,
  error: "",
};

//Thunk operations

export const fetchDataforFilters = createAsyncThunk(
  "userFlow/fetchDataforFilters",
  /**
   *
   * @param  arg {id:string , selectedScreen }
   * @returns
   */

  async (
    {
      appliedFilters,
      screen,
      page,
      count = 25,
    }: {
      appliedFilters: Record<string, string | string[]>;
      screen: string;
      page: number;
      count?: number;
    },
    { dispatch, rejectWithValue }
  ) => {
    let response, updatedFilters;

    updatedFilters = { ...appliedFilters };

    let removeNullAppliedFilters = removeNullValues(updatedFilters);

    response = await getAllDataForFilters(
      removeNullAppliedFilters,
      screen,
      page,
      count
    );

    if (response instanceof AxiosError) {
      dispatch(setFilteredData({ data: undefined, screen }));
      throw rejectWithValue(response?.response?.data);
    }
    dispatch(setFilteredData({ data: response, screen }));
    return response;
  }
);

export const fetchSavedSearches = createAsyncThunk(
  "userFlow/getSavedSearches",
  async (_, { rejectWithValue }) => {
    let response;
    response = await getSavedSearches();
    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data.detail);
    }
    return response;
  }
);

export const saveSavedSearches = createAsyncThunk(
  "userFlow/saveSavedSearches",
  /**
   *
   * @param  arg {id:string , selectedScreen }
   * @returns
   */
  async (
    data: {
      filters: { [x: string]: string[] };
      filterName: string;
      screen: string;
      status: string;
      id: string;
      userId: string;
    },
    { rejectWithValue }
  ) => {
    const response = await createSavedSearches(data);

    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data);
    }
    return { res: response, data };
  }
);

export const deleteSavedSearch = createAsyncThunk(
  "userFlow/deleteSavedSearches",
  async (
    data: { id: string; page: number; filterName: string },
    { rejectWithValue }
  ) => {
    let response;
    response = await deleteSavedSearches(data);
    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data.detail);
    }
    return { message: response, id: data.id, page: data.page };
  }
);

export const sendDataToFilterService = createAsyncThunk(
  "userFlow/sendTofilterService",
  async (
    data: {
      userId: string;
      screen: string;
      token?: string | null;
      filterName: string;
      filters: { [x: string]: string[] };
    },
    { rejectWithValue }
  ) => {
    let response;
    response = await sendDatatoFilterServiceCall(data, "add");
    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data.detail);
    }
    return response;
  }
);

export const setStateForViewSaveSearch = createAsyncThunk(
  "userFlow/setViewStateForViewSaveSearch",
  async (filterId: string) => {
    const response = await setViewedForSavedSearch(filterId);

    return response;
  }
);

export const saveAndSendFilterData = createAsyncThunk(
  "userFlow/saveAndSendFilterData",
  async (args: {
    filterData: {
      filters: Array<{ field: string; values: string[] }>;
    };
  }) => {
    try {
      // const { filterData, screenName, userId, token } = args;
      //let response = await setViewedForSavedSearch(filterData.id)
      return args;
    } catch (error) {
      console.error("Error in saveAndSendFilterData:", error);
      throw error; // this will make the thunk be rejected
    }
  }
);

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setDataForfilterSlice: () => {},

    setAddtoFilters: (state, action) => {
      if (state.selectedRows instanceof Array) {
        state.selectedRows[0] = [...action.payload];
      }
    },
    saveSelectedFilter: (state, action) => {
      const { filters, filterID, filterName } = action.payload;
      state.selectedFilters = filters;
      state.currentFilterId = filterID;
      state.filterName = filterName;
    },

    saveAppliedFilters: (state, action) => {
      const result = Object.keys(action.payload).find((key) => {
        const value = action.payload[key];
        return (
          Array.isArray(value) &&
          (value.length === 0 || value.every((item) => item === ""))
        );
      });

      if (result) {
        delete state.selectedFilters[result];
      } else {
        state.selectedFilters = {
          ...state.selectedFilters,
          ...action.payload,
        };
      }
    },

    clearFilters: (state) => {
      state.selectedFilters = {};
      state.currentFilterId = "";
      state.filterName = "";
      state.filterFlag = false;

      state.viewFilter.filters = "";
      state.viewFilter.currentFilterId = "";
      state.viewFilter.filterName = "";
      state.viewFilter.screenName = "";
    },

    resetStatus: (state) => {
      state.status = RESPONSE_STATUS.IDLE;
    },

    setFilterFlag: (state, action) => {
      state.filterFlag = action.payload;
    },

    setViewFilters: (state, action) => {
      const { filterData, screenName } = action.payload;
      const ff = processFilterFormat(action.payload.filterData);

      state.selectedFilters = ff;
      state.viewFilter.filters = filterData;
      state.viewFilter.currentFilterId = filterData.id;
      state.viewFilter.filterName = filterData.filterName;
      state.viewFilter.screenName = screenName;
      state.viewFilter.viewFlag = true;
    },

    setViewFlag: (state, action) => {
      state.viewFilter.viewFlag = action.payload;
    },

    setEditAndSave: (state, action) => {
      state.editAndSave = action.payload;
    },
    setFilterName: (state, action) => {
      state.filterName = action.payload;
    },
    setFilterSliceToIntialState: (state) => {
      state = { ...initialState };
    },
    setFilterDrawerStatus: (state, action) => {
      state.filterDrawer = action.payload;
    },
    setApplyFilterStatus: (state, action) => {
      state.applyFilter = action.payload;
    },
    resetErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataforFilters.pending, (state) => {
      state.status = RESPONSE_STATUS.LOADING;
    });
    builder
      .addCase(fetchDataforFilters.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.filterFlag = true;
        state.applyFilter = false;

        // state.viewFilter.viewFlag = false;
        // state.viewFilter.filters = {};
        // state.viewFilter.currentFilterId = '';
        // state.viewFilter.filterName = ''
        // state.viewFilter.screenName  = '';
      })
      .addCase(fetchDataforFilters.rejected, (state) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.applyFilter = false;
      })
      .addCase(fetchSavedSearches.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchSavedSearches.rejected, (state) => {
        state.status = RESPONSE_STATUS.FAILED;
      })
      .addCase(fetchSavedSearches.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        let savedSearches: Record<string, any> = {};
        const filtersSaved = action.payload;
        filtersSaved?.forEach((x: { page: string | number }) => {
          let key = mapToURL[x.page];
          if (!savedSearches[key]) {
            savedSearches[key] = [];
          }
          savedSearches[key].push(x);
        });
        state.savedSearches = { ...savedSearches };
      })
      .addCase(deleteSavedSearch.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteSavedSearch.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        const { id, page } = action.payload;
        let filtersSaved = { ...state.savedSearches };
        filtersSaved[mapToURL[page]] = filtersSaved[mapToURL[page]].filter(
          (x: { id: string }) => x.id !== id
        );
        state.savedSearches = { ...filtersSaved };
      })
      // .addCase(saveAndSendFilterData.rejected, (_, action) => {})
      .addCase(saveAndSendFilterData.fulfilled, (state, action) => {
        // const { filters, screenName, filterID, filterName } =
        //   action.payload.filterData;

        const ff = processFilterFormat(action.payload?.filterData);
        state.selectedFilters = ff;
      })
      .addCase(saveSavedSearches.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(saveSavedSearches.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as
          | { message: string; error: string }
          | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(saveSavedSearches.fulfilled, (state, action) => {
        const { res, data } = action.payload;
        if (res) {
          state.currentFilterId =
            res.filters[
              res.filters?.findIndex(
                (x: { filterName: any }) => data.filterName === x.filterName
              )
            ];
          state.filterName = data.filterName;
          state.notification = {
            type: "success",
            message: "Saved search created Successfully",
          };
          state.status = RESPONSE_STATUS.SUCCEEDED;
        } else {
          state.status = RESPONSE_STATUS.FAILED;
          state.error = "Limit reached for your subscription level";
          state.notification = {
            type: "error",
            message: "Limit exceeded for free user. Please upgrade your plan",
          };
        }
      });
  },
});

export const {
  // setAddtoLeads,
  saveSelectedFilter,
  setViewFilters,
  // defaultFilterDropdown,
  setViewFlag,

  setFilterFlag,
  saveAppliedFilters,
  setEditAndSave,
  setFilterName,
  clearFilters,
  setFilterSliceToIntialState,
  setFilterDrawerStatus,
  setApplyFilterStatus,
  resetErrors,
  resetStatus,
} = filterSlice.actions;
