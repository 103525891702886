import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { filterSlice } from "./slice/filterSlice.js";
import { userFlowSlice } from "./slice/userFlowSlice.js";
import { signinSlice } from "./slice/signinSlice.js";
import { dataHiresSlice } from "./slice/dataHiresSlice.js";
import profileReducer from "./slice/profileSlice.js";
import { fundingSlice } from "./slice/fundingSlice.js";
import { jobPostingSlice } from "./slice/jobPostingSlice.js";
import { newsSlice } from "./slice/newsSlice.js";
import { createProfileSlice } from "./slice/adminSlice/createProfileSlice.js";
import { adminJobSlice } from "./slice/adminSlice/adminJobSlice.js";
import { adminHireSlice } from "./slice/adminSlice/adminHireSlice.js";
import { adminNewsSlice } from "./slice/adminSlice/adminNewsSlice.js";
import { subscriptionChartSlice } from "./slice/chartSlice/subscriptionChartSlice.js";
import { adminFundingSlice } from "./slice/adminSlice/adminFundingSlice.js";
import { hiresCountChartSlice } from "./slice/chartSlice/hiresCountChartSlice.js";
import { businessCountSlice } from "./slice/chartSlice/businessCountSlice.js";
import { fundingCountSlice } from "./slice/chartSlice/fundingCountSlice.js";
import { jobsCountSlice } from "./slice/chartSlice/jobsCountSlice.js";
import universalDataReducer from "./slice/universalDataSlice.js";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { scrappedCountSlice } from './slice/chartSlice/scrapperCountSlice.js';
import industrySliceReducer from './slice/adminSlice/industrySlice.js';
import jobTitlesSliceReducer from './slice/adminSlice/jobTitlesSlice.js';

const rootReducer = combineReducers({
  userflow: userFlowSlice.reducer, //calling the reducer file,
  signin: signinSlice.reducer,
  profile: profileReducer,
  dataHires: dataHiresSlice.reducer,
  filters: filterSlice.reducer,
  dataFunds: fundingSlice.reducer,
  jobPosting: jobPostingSlice.reducer,
  news: newsSlice.reducer,
  adminProfile: createProfileSlice.reducer,
  adminJob: adminJobSlice.reducer,
  adminHires: adminHireSlice.reducer,
  adminNews: adminNewsSlice.reducer,
  adminFunds: adminFundingSlice.reducer,
  subscriptionChart: subscriptionChartSlice.reducer,
  hiresCountChart: hiresCountChartSlice.reducer,
  businessCount: businessCountSlice.reducer,
  fundingCount: fundingCountSlice.reducer,
  jobsCount: jobsCountSlice.reducer,
  universalData: universalDataReducer,
  scrappedCount: scrappedCountSlice.reducer,
  industries: industrySliceReducer,
  jobTitles: jobTitlesSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
