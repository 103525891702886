import {
  baseUrl,
  devURL,
  // myLeadsPostURL,
  // userFlowURL,
  // myLeadsFetchURL,
  getSavedSearchesURL,
  filterApplyURL,
  saveFilterURL,
  mapToFilterURL,
  // mapToURL,
  mapToDeleteFilterURL,
} from "../constants/userFlowConstants";
import { TOKEN } from "../enums/enums";
import { axiosInstance } from "../secutityUtils/axiosInstance";
import { getMappedValue } from "./userFlowService";

// const _url =`${baseURL}${saveFilterURL[data.screen]}/${data.id}`

const baseURL = `${devURL}${baseUrl}`;
// const url = '';
// const token = localStorage.getItem('jwtToken')

/**
 *
 */

/**
 * Save filters Api call
 */

const removeNullValues = (filters: { [x: string]: string | string[] }) => {
  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => {
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        !value.every((val) => !val)
      ) {
        return value;
      }
      return _;
    })
  );

  return addArrayToEmplyoeeCount(filteredFilters);
};

const addArrayToEmplyoeeCount = (data: { [x: string]: any }) => {
  if (!!data["companyEmployeeCount"]) {
    data["companyEmployeeCount"] = Array.isArray(data["companyEmployeeCount"])
      ? data["companyEmployeeCount"]
      : [data["companyEmployeeCount"]];
  }
  if (!!data["fundingRaised"]) {
    data["fundingRaised"] = Array.isArray(data["fundingRaised"])
      ? data["fundingRaised"]
      : [data["fundingRaised"]];
  }
  return { ...data };
};

const formatInnerFilters = (inputObj: { [s: string]: string }) => {
  return Object.entries(inputObj).map(([key, value]) => {
    return {
      field: key,
      values: value,
    };
  });
};

const formatInnerFiltersForFilterService = (inputObj: {
  [s: string]: string;
}) => {
  return Object.entries(inputObj).map(([key, value]) => {
    return {
      field: key,
      value: Array.isArray(value) ? value : [value],
    };
  });
};

const filtersChanged = (
  currentFilter: { [x: string]: string[] },
  prevFilters: string[]
) => {
  const prevFilterStr = JSON.stringify(prevFilters);
  const currentFilterStr = JSON.stringify(currentFilter);

  return prevFilterStr !== currentFilterStr;
};

const checkPrevWithCurrentFilters = (
  updatedFiltersCopy: { [x: string]: string[] },
  prevFilters: string[]
) => {
  if (filtersChanged(updatedFiltersCopy, prevFilters)) {
    // setApplyButton enable , set prevFilters to currentApplyFilters
    return false;
  } else if (prevFilters.length === 0) {
    // setApplybutton diabled
    return true;
  } else {
    // setApplybutton diabled
    return true;
  }
};

/**
 * Apply filters Api Call
 */

const getAllDataForFilters = async (
  filterData: { [x: string]: string },
  screen: string,
  page: number,
  count: number
) => {
  const url = `${baseURL}${filterApplyURL[screen]}?page=${page}&count=${count}`;
  try {
    const response = await axiosInstance.post(url, filterData);
    return response.data;
  } catch (error) {
    return error;
  }
};

const getSavedSearches = async () => {
  const baseURL = `${import.meta.env.VITE_DEV_USER_URL}/api/v1/`; // Update with your actual base URL
  const url = `${baseURL}${getSavedSearchesURL}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteSavedSearches = async (data: {
  id?: string;
  page: number;
  filterName?: string;
}) => {
  const baseURL = `${import.meta.env.VITE_DEV_USER_URL}/api/v1/`; // Update with your actual base URL
  const url = `${baseURL}userService/savedSearches/${data.page}/${data.id}`;
  try {
    const response = await axiosInstance.delete(url);
    if (response) {
      deleteApiFilterService(data);
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

const createSavedSearches = async (data: {
  filterName?: string;
  filters?: { [x: string]: string[] };
  id?: string;
  status?: string;
  screen?: string;
  userId?: string;
}) => {
  try {
    if (data.filters) {
      const url =
        data.status === "add"
          ? `${baseURL}${saveFilterURL[data?.screen || ""]}`
          : `${baseURL}${saveFilterURL[data?.screen || ""]}/${data.id}`;
      const body =
        data.status === "add"
          ? {
              filterName: data.filterName,
              visited: true,
              innerFilters: formatInnerFilters(removeNullValues(data.filters)),
            }
          : {
              filterName: data.filterName,
              id: data?.id,
              innerFilters: formatInnerFilters(removeNullValues(data.filters)),
            };
      const response = await axiosInstance({
        url,
        method: data.status === "add" ? "POST" : "PUT",
        data: body,
      });

      const dataUp = {
        userId: data.userId,
        screen: data.screen,
        filterName: data.filterName,
        filters: data.filters,
      };

      if (dataUp) {
        await sendDatatoFilterServiceCall(dataUp, data.status);
      }
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

const deleteApiFilterService = async (data: {
  page: number;
  filterName?: string;
}) => {
  try {
    const baseURL = import.meta.env.VITE_DEV_FILTER_URL;
    const url = `${baseURL}/filters1/deleteFilter`;
    let body = {
      filterPage: getMappedValue(data.page, mapToDeleteFilterURL),
      token: localStorage.getItem(TOKEN.JWT_TOKEN),
      filterName: data.filterName,
    };
    const response = await axiosInstance.delete(url, { data: body });

    return response.data;
  } catch (error) {
    return error;
  }
};

const sendDatatoFilterServiceCall = async (
  data: {
    userId?: string;
    screen?: string;
    filterName?: string;
    filters?: { [x: string]: string[] };
  },
  status?: string
) => {
  if (data.screen && data.filters) {
    const token = localStorage.getItem("jwtToken");
    const baseURL = import.meta.env.VITE_DEV_FILTER_URL;
    const url = `${baseURL}/filters1/filter`;
    const body = {
      userId: data.userId,
      page: getMappedValue(data.screen, mapToFilterURL),
      token,
      filterName: data.filterName,
      isVisited: true,
      innerFilters: formatInnerFiltersForFilterService(
        removeNullValues(data.filters)
      ),
    };
    const response = await axiosInstance({
      url,
      method: status === "add" ? "POST" : "PUT",
      data: body,
    });
    return response.data;
  }
};

const setViewedForSavedSearch = async (filterId: string) => {
  try {
    const baseURL = import.meta.env.VITE_DEV_FILTER_URL;
    const url = `${baseURL}/filters1/${filterId}`;

    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    return error;
  }
};

const sendToFilterServicePercolate = async (data: any) => {
  try {
    const baseURL = import.meta.env.VITE_DEV_FILTER_URL;
    const url = `${baseURL}/filters1/percolate`;

    const response = await axiosInstance.post(url, data);

    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Check whether unique values have been changed - enable and disable Apply Filters Button
 */

export {
  getAllDataForFilters,
  createSavedSearches,
  removeNullValues,
  checkPrevWithCurrentFilters,
  getSavedSearches,
  deleteSavedSearches,
  sendDatatoFilterServiceCall,
  setViewedForSavedSearch,
  sendToFilterServicePercolate,
};
