import {
  baseUrl,
  devURL,
  myLeadsPostURL,
  userFlowURL,
  myLeadsFetchURL,
} from "../constants/userFlowConstants";
import mockValue from "../assets/mockData/MOCK_DATA.json";
import { axiosInstance } from "../secutityUtils/axiosInstance";

const fetchURL = `${devURL}${baseUrl}`;

/**
 *
 * Below is get all data needed for Table for four Different screens
 */

// Get All data for New Hires
const fetchData = async (
  endpointURL: string,
  page: number,
  count: number,
  filters = ""
) => {
  const url = `${fetchURL}${endpointURL}?page=${page}&count=${count}${filters}`;

  try {
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    return error;
  }
};
const newHiresFetchAllData = (page: number, count: number, filters?: string) =>
  fetchData(userFlowURL.newHiresURL, page, count, filters);
const jobPostingsFetchAllData = (
  page: number,
  count: number,
  filters?: string
) => fetchData(userFlowURL.jobPostingURL, page, count, filters);
const pressReleaseFetchAllData = (
  page: number,
  count: number,
  filters?: string
) => fetchData(userFlowURL.pressReleaseURL, page, count, filters);
const fundingsFetchAllData = (page: number, count: number, filters?: string) =>
  fetchData(userFlowURL.fundingsURL, page, count, filters);

const testData: () => Promise<
  {
    id: number;
    firstName: string;
    lastName: string;
    position: string;
    companyName: string;
    companyHQ: string;
    industry: string;
    companyEmployeeCount: number;
    companyLinkedIn: string;
  }[]
> = () => {
  return new Promise((resolve) => {
    resolve(mockValue);
  });
};

/**
 *
 * Add to Leads Logic
 */
const setLeadsToScreens = async (screenName: string, data: any) => {
  return postSetLeadsPage(data[screenName], myLeadsPostURL[screenName]);
};

const postSetLeadsPage = async (data: any, url: string) => {
  try {
    const response = await axiosInstance.post(`${fetchURL}${url}`, data);

    return response.data;
  } catch (error) {
    // Handle any network or other errors here
    return error;
  }
};

const deleteLeads = async (deleteID: string[], screen: string) => {
  try {
    const response = await axiosInstance.delete(
      `${fetchURL}${myLeadsFetchURL[screen]}`,
      { data: deleteID }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *
 * FETCH ALL SAVED SEARCHES
 */

const processRowDataWithCheckedBox = (
  data: any[],
  selectedRows: Array<any>
) => {
  return data.map((x) => {
    let checked = false;
    if (selectedRows?.includes(x.id)) {
      checked = true;
    }
    return {
      checked,
      ...x,
      fundingRaised: new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
        maximumSignificantDigits: 2,
      }).format(x.fundingRaised),
      companyEmployeeCount: new Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumSignificantDigits: 1,
      }).format(x.companyEmployeeCount),
    };
  });
};

//  const getAllDataForScreens = async (id) =>  {
//     return (await fetch(url + 'user')).json();
// }

const getMappedValue = (input: string | number, object: any) => {
  return (
    object[input] ||
    Object.keys(object).find((key) => object[key] === input) ||
    null
  );
};

export {
  newHiresFetchAllData,
  jobPostingsFetchAllData,
  pressReleaseFetchAllData,
  fundingsFetchAllData,
  setLeadsToScreens,
  testData,
  getMappedValue,
  processRowDataWithCheckedBox,
  deleteLeads,
};
