import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  queryTabsRecord,
  userFlowScreens,
} from "../../constants/userFlowConstants";
import {
  fundingsFetchAllData,
  jobPostingsFetchAllData,
  setLeadsToScreens,
  newHiresFetchAllData,
  pressReleaseFetchAllData,
  testData,
  processRowDataWithCheckedBox,
  deleteLeads,
} from "../../services/userFlowService";
import { AxiosError } from "axios";
import { RESPONSE_STATUS } from "../../enums/enums";
import { errorHandler } from "./helper/errorHandler";
import { axiosInstance } from "../../secutityUtils/axiosInstance";

const fetchURL = `${import.meta.env.VITE_DEV_USER_URL}/api/v1`;

const createEmptyListObject = (): Record<string, Array<string>> => ({
  newHires: [],
  fundings: [],
  jobPostings: [],
  pressReleases: [],
});
type InitialState = Record<string, any> & { error: string };
let initialState: InitialState = {
  ...createEmptyListObject(),
  hires: [],
  funding: [],
  jobs: [],
  news: [],
  selectedRows: createEmptyListObject(),
  leadsFlag: true,
  deletedRows: createEmptyListObject(),
  leadsUpdateStatus: false,
  loader: false,
  status: RESPONSE_STATUS.IDLE,
  drawerState: false,
  error: "",
  pdfLoader: false,
  pdfError: null,
  pdfSuccessMsg: false,
};

//Thunk operations

export const fetchDataforScreens = createAsyncThunk(
  "userFlow/fetchData",
  /**
   *
   * @param  arg {selectedScreen }
   * @returns
   */
  async (
    {
      selectedScreen,
      page,
      count = 25,
      filtersArray,
    }: {
      selectedScreen: string;
      page: number;
      count?: number;
      filtersArray?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    let filters = "";
    let response: any = await testData();

    filtersArray?.forEach((item) => {
      filters += `&${item.key}=${item.value}`;
    });

    switch (selectedScreen) {
      case userFlowScreens[0]:
        response = await newHiresFetchAllData(page, count, filters);
        break;
      case userFlowScreens[1]:
        response = await fundingsFetchAllData(page, count, filters);
        break;
      case userFlowScreens[2]:
        response = await jobPostingsFetchAllData(page, count, filters);
        break;
      case userFlowScreens[3]:
        response = await pressReleaseFetchAllData(page, count, filters);
        break;
    }

    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data.detail);
    }

    return { data: response, screen: selectedScreen };
  }
);

// Once Fetch data is done , dispatch event to set filters
/**
 *
 * @param  arg {selectedScreen }
 * @returns
 */
export const addToMyLeads = createAsyncThunk(
  "userFlow/setLeadsData",

  async (leadsData: { screen: any; data: any }, { rejectWithValue }) => {
    let response = await setLeadsToScreens(leadsData.screen, leadsData.data);
    if (response instanceof AxiosError) {
      throw rejectWithValue(response?.response?.data);
    }
    return { data: response, id: leadsData.data, screen: leadsData.screen };
  }
);

export const fetchLeadsData = createAsyncThunk(
  "userFlow/fetchLeadsData",
  async (lead: string, { rejectWithValue }) => {
    const response = await axiosInstance
      .get(`${fetchURL}/userService/myLeads/${lead}`)
      .then((data) => data)
      .catch((err) => {
        if (err instanceof AxiosError) {
          throw rejectWithValue(lead);
        }
      });

    return { data: response?.data, lead };
  }
);

export const deleteLeadsfromRow = createAsyncThunk(
  "userFlow/deleteLeadsfromRow",
  async ({
    deletedRowID,
    screen,
  }: {
    deletedRowID: string[];
    screen: string;
  }) => {
    let response = await deleteLeads(deletedRowID, screen);
    return { data: response, screen };
  }
);

export const userFlowSlice = createSlice({
  name: "user-flow",
  initialState,
  reducers: {
    setDataForUserFlowScreens: () => {},

    setAddRowIdtoLeads: (state, action) => {
      const { data, screen, oldData } = action.payload;

      const newData =
        oldData.length > 0
          ? data.filter(
              (id: string) =>
                !oldData.some((item: { id: string }) => item.id === id)
            )
          : data;

      state.selectedRows[screen] = newData;
    },

    setRowsReal: (
      state,
      action: { payload: { data: any; screen: string } }
    ) => {
      const { data, screen } = action.payload;
      const finalData =
        state.pageNumber > 0 ? [...data, ...state[screen]] : data;
      const selectedRows = state[queryTabsRecord[screen]].map(
        (x: { id: string }) => x.id
      );
      state[screen] = processRowDataWithCheckedBox(finalData, selectedRows);
    },

    setDeletedLeadsData: (state, action) => {
      const { deletedRowID, screen } = action.payload;
      state.deletedRows[screen] = deletedRowID;
      state.leadsUpdateStatus = true;
    },

    setLeadsFlag: (state, action) => {
      state.leadsFlag = action.payload;
    },

    setFilteredData: (state, action) => {
      // const { data, screen } = action.payload;

      const { data, screen } = action.payload;

      if (data) {
        // state[screen]=[]
        const finalData =
          state.pageNumber > 0 ? [...state[screen], ...data] : data;
        const selectedRows = state[queryTabsRecord[screen]].map(
          (x: { id: string }) => x.id
        );

        state[screen] = processRowDataWithCheckedBox(
          finalData.data,
          selectedRows
        );

        state.maxRecords = finalData.totalElements;
      } else {
        state[screen] = [];
        state.maxRecords = 0;
      }

      // state[screen] = val;
    },

    setNewSelectedRowsEmpty: (state) => {
      state.selectedRows = createEmptyListObject();
    },

    setDeletedRowsEmpty: (state) => {
      state.deletedRows = createEmptyListObject();
    },

    setUserFlowToIntialState: (state) => {
      state = initialState;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setdrawerState: (state, action) => {
      state.drawerState = action.payload;
    },
    resetErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToMyLeads.pending, (state) => {
        state.updateStatus = RESPONSE_STATUS.LOADING;
      })
      .addCase(addToMyLeads.fulfilled, (state, action) => {
        state.updateStatus = RESPONSE_STATUS.SUCCEEDED;
        const { screen } = action.payload;
        state.selectedRows[screen] = [];
      })
      .addCase(addToMyLeads.rejected, (state, action) => {
        state.updateStatus = RESPONSE_STATUS.FAILED;

        state.error = action.payload as string;
      })
      .addCase(fetchLeadsData.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchLeadsData.fulfilled, (state, action) => {
        //Process each array and set it to screens
        state.status = RESPONSE_STATUS.SUCCEEDED;
        const { data, lead } = action.payload;
        state[lead] = data;
      })
      .addCase(fetchLeadsData.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        if (typeof action.payload === "string") {
          state[action.payload] = [];
        }
      })
      .addCase(fetchDataforScreens.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchDataforScreens.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        const { data, screen } = action.payload;

        const selectedRows = state[queryTabsRecord[screen]].map(
          (x: { id: string }) => x.id
        );

        state.maxRecords = data.totalElements;
        state[screen] = processRowDataWithCheckedBox(data.data, selectedRows);
      })
      .addCase(fetchDataforScreens.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteLeadsfromRow.pending, (state) => {
        state.deleteStatus = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteLeadsfromRow.fulfilled, (state, action) => {
        state.deleteStatus = RESPONSE_STATUS.SUCCEEDED;
        const { screen } = action.payload;
        state.deletedRows[screen] = [];
        state.leadsUpdateStatus = false;
      })
      .addCase(deleteLeadsfromRow.rejected, (state) => {
        state.deleteStatus = RESPONSE_STATUS.FAILED;
      });
  },
});

export const {
  setAddRowIdtoLeads,
  setLeadsFlag,
  setFilteredData,
  setNewSelectedRowsEmpty,
  setDeletedRowsEmpty,
  setDeletedLeadsData,
  setRowsReal,
  setLoader,
  setdrawerState,
  resetErrors,
} = userFlowSlice.actions;
