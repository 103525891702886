import SubscriptionChart from "../Charts/SubscriptionChart";
import HiresCountChart from "../Charts/HiresCountChart";
import BusinessCountChart from "../Charts/BusinessCountChart";
import FundingCountChart from "../Charts/FundingCountChart";
import JobsCountChart from "../Charts/JobsCountChart";
import "./AdminDashboard.scss";
import ScrappedCountChart from '../Charts/ScrappedCountChart';

function AdminDashboard() {
  return (
    <>
      <div className="chart-container">
        <div className="chart">
          <SubscriptionChart />
        </div>
        <div className="chart">
          <HiresCountChart />
        </div>
        <div className="chart">
          <BusinessCountChart />
        </div>
        <div className="chart">
          <FundingCountChart />
        </div>
        <div className="chart">
          <JobsCountChart />
        </div>
        <ScrappedCountChart />
      </div>
    </>
  );
}

export default AdminDashboard;
