import { Fragment, useEffect, useState, useContext, useMemo } from "react";
import Table from "../../../features/Table/Table";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import {
  fetchDataforScreens,
  setAddRowIdtoLeads,
  setDeletedLeadsData,
} from "../../../store/slice/userFlowSlice";
import {
  queryTabs,
  userFlowScreens,
} from "../../../constants/userFlowConstants";
import {
  generateHeaderColumns,
  processFilterFormat,
} from "../../../utils/userFlow";
import {
  fetchDataforFilters,
  setApplyFilterStatus,
} from "../../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { rowsInterface } from "../../../interfaces/interfaces";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import usePagination from "../../../hooks/usePagination";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function JobPostings() {
  const userFlowStatus = useAppSelector((state) => state.userflow.status);
  const dispatch = useAppDispatch();
  const rowsReal: rowsInterface[] = useAppSelector(
    (state) => state.userflow[userFlowScreens[2]]
  );
  const maxRecords = useAppSelector((state) => state.userflow.maxRecords);
  const maxFilteredData = useAppSelector((state) => state.filters.maxRecords);
  const leadRowData: Array<{ id: string }> = useAppSelector(
    (state) => state.userflow[queryTabs[2]]
  );

  const [checkedRowIds, setCheckedRowIds] = useState<GridRowSelectionModel>([]);
  const [rowCount, setRowCount] = useState(rowsReal.length);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const apiRef = useGridApiRef();
  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const { paginationModel, updatePageParams } = usePagination();

  const selectedFilters = useAppSelector(
    (state) => state.filters.selectedFilters
  );

  const isFiltersSelected = useAppSelector(
    (state) => state.filters.applyFilter
  );

  const noFiltersPresent = useMemo(
    () => selectedFilters && Object.keys(selectedFilters).length === 0,
    [selectedFilters]
  );

  const viewFilters = useAppSelector((state) => state.filters.viewFilter);

  useEffect(() => {
    if (viewFilters.viewFlag) {
      const filterValue = processFilterFormat(viewFilters.filters);
      dispatch(
        fetchDataforFilters({
          appliedFilters: filterValue,
          screen: userFlowScreens[2],
          page: paginationModel.page,
        })
      );
    }
  }, [viewFilters, dispatch, paginationModel]);

  useEffect(() => {
    if (isFiltersSelected) {
      dispatch(
        fetchDataforFilters({
          appliedFilters: selectedFilters,
          screen: userFlowScreens[2],
          page: paginationModel.page,
        })
      );
    }
  }, [selectedFilters, dispatch, isFiltersSelected, paginationModel]);

  useEffect(() => {
    if (!noFiltersPresent && !viewFilters.viewFlag) {
      dispatch(setApplyFilterStatus(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, dispatch, viewFilters]);

  useEffect(() => {
    if (noFiltersPresent) {
      dispatch(
        fetchDataforScreens({
          selectedScreen: userFlowScreens[2],
          page: paginationModel.page,
          filtersArray: filters,
        })
      );
    }
  }, [dispatch, paginationModel, noFiltersPresent, filters]);

  useEffect(
    () => setRowCount(rowsReal.length || rowCount),
    [rowsReal.length, rowCount]
  );

  useEffect(() => {
    if (leadRowData?.length) {
      setCheckedRowIds(leadRowData.map((x) => x.id));
    }
  }, [leadRowData, rowsReal]);

  useEffect(() => {
    if (userFlowStatus === RESPONSE_STATUS.SUCCEEDED) {
      setIsLoading(false);
    }
  }, [setIsLoading, userFlowStatus]);

  const handleSelectionRowChange = (newSelection: GridRowSelectionModel) => {
    setCheckedRowIds(newSelection); // Update state with the new selection
    //check whether anthing deleted

    const idsFromDataObjects = leadRowData.map(
      (object: { id: string }) => object.id
    );
    let idsNotInDataObjects = idsFromDataObjects.filter(
      (id: string) => !newSelection.includes(id)
    );

    dispatch(
      setDeletedLeadsData({
        deletedRowID: idsNotInDataObjects,
        screen: userFlowScreens[2],
        currentSelection: newSelection,
      })
    );

    dispatch(
      setAddRowIdtoLeads({
        data: newSelection,
        screen: userFlowScreens[2],
        oldData: leadRowData,
      })
    );
  };

  const columns = generateHeaderColumns(userFlowScreens[2]);

  return (
    <Fragment>
      <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
        <Table
          rows={rowsReal}
          columns={columns}
          checkboxSelection={true}
          onRowSelectionModelChange={handleSelectionRowChange}
          rowCount={maxFilteredData ?? maxRecords}
          isLoading={isLoading}
          apiRef={apiRef}
          paginationModel={paginationModel}
          onPaginationModelChange={updatePageParams}
          selectionModel={checkedRowIds}
          onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
          paginationMode={"server"}
        />
      </div>
    </Fragment>
  );
}

export default JobPostings;
